<template >
  <a-modal title="审核" :visible="modal.visible" :confirm-loading="modal.confirmLoading" @ok="submit()"  @cancel="hide" >
    <a-row >
      <a-col v-for="item in list" :span="12">
        <a-card hoverable style="width: 240px" :bordered="true">
          <img
            slot="cover"
            alt="example"
            :src="item.imgUrl"
          />
        </a-card>
      </a-col>
      <a-col :span="24" >
        <div style="background:#ECECEC; padding:30px">
          <a-card title="order detail" :bordered="false" style="width: 100%">
            <p>订单号：{{this.order.orderNo}} | 姓名：{{this.order.name}}</p>
            <p>手机号：{{this.order.mobileNo}} | 本金：{{this.order.capital}}</p>
            <p>已结清本金：{{this.order.settledCapital}} | 利息：{{this.order.interest}} </p>
            <p>已结清利息：{{this.order.settledInterest}} | 滞纳金：{{this.order.lateFee}}</p>
            <p>抵扣金额：{{this.order.deductionFee}} | 剩余金额：{{this.order.repayAmt}}</p>
          </a-card>
        </div>
      </a-col>
      <a-col :span="24" >
        <div style="display: flex;align-items:center;justify-content:center;margin-top: 12px;">
          <a-input v-model="model.settleDate"  style="width: 100%; margin-right: 15px;" placeholder="结清日期"/>
        </div>
        <div style="display: flex;align-items:center;justify-content:center;margin-top: 12px;">
          <a-input v-model="model.amount" style="width: 100%; margin-right: 15px;" placeholder="审核金额"/>
        </div>
      </a-col>
    </a-row>
    <template slot="footer">
      <a-button type="primary" @click="submitAll()">线下全额还款</a-button>
      <a-button type="primary" @click="submit()">线下部分还款</a-button>
      <a-button type="primary" @click="hide">取消</a-button>
    </template>
  </a-modal>
</template>
<script>
  import {getOrderImageInfoList,reviewImage} from '@/api/orderImageInfo'
  import { repayOffline,  repayOfflineAll} from '@/api/order'
  export default {
    name: 'CreateGroupModalOrderList',
    data() {
      return {
        modal: {
          visible: false,
          confirmLoading: false,
        },
        form: this.$form.createForm(this),
        model: {
          orderInfoId: 0,
          amount: 0,
          settleDate: ''
        },
        list:[],
        order:{
          capital: 0,
          settledCapital: 0,
          interest: 0,
          settledInterest: 0,
          lateFee: 0,
          deductionFee: 0,
          repayAmt: 0,
          name : '',
          mobileNo : '',
          orderNo : ''
        }
      }
    },
    methods: {
      show(orderInfoId) {
        this.order.capital = orderInfoId.capital;
        this.order.settledCapital = orderInfoId.settledCapital;
        this.order.interest = orderInfoId.interest;
        this.order.settledInterest = orderInfoId.settledInterest;
        this.order.lateFee = orderInfoId.lateFee;
        this.order.deductionFee = orderInfoId.deductionFee;
        this.order.repayAmt = orderInfoId.repayAmt;
        this.order.name = orderInfoId.firstName + ' ' + orderInfoId.secondName + ' ' + orderInfoId.lastName;
        this.order.orderNo = orderInfoId.orderNo;
        this.order.mobileNo = orderInfoId.mobileNo;

        this.list = []
        this.modal.visible = true
        this.model.orderInfoId = orderInfoId.id
        getOrderImageInfoList(this.model).then(res => {
          this.loading = false;
          this.list = res && res.data && res.data.rows;
          this.total = res && res.data && res.data.total;
        }).catch(e => {
          this.loading = false;
          this.$message.error(e.message);
        });
      },
      hide() {
        // this.$message.info("1212121212");
        this.modal.visible = false
        this.model = {
          orderInfoId: 0,
          amount: 0,
          settleDate: ''
        }
      },
      submitAll() {

        var repayAmt = this.model.amount;
        if("0" == repayAmt){
          this.$message.error("金额不能为0");
          return;
        }

        repayOfflineAll({ orderId: this.model.orderInfoId
          , repayAmt: Number(repayAmt), settleDate: this.model.settleDate }).then(res => {

          this.$message.info("线下全额还款成功");
          this.hide()
          this.$emit("refresh")
        }).catch(e => {
          this.$message.error(e.message);
        })
      },
      submit() {
        var repayAmt = this.model.amount;
        if("0" == repayAmt){
          this.$message.error("金额不能为0");
          return;
        }

        repayOffline({ orderId: this.model.orderInfoId
          , repayAmt: Number(repayAmt), settleDate: this.model.settleDate }).then(res => {
          this.$message.info("线下部分还款成功");
          this.hide()
          this.$emit("refresh")
        }).catch(e => {
          this.$message.error(e.message);
        })

      }
    }
  }
</script>
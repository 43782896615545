import { axios } from '@/utils/request'

export function getOrderImageList (parameter = {}) {
  return axios({
    url: '/orderImage/orderImageList',
    method: 'post',
    data: parameter
  })
}
export function getOrderImageInfoList (parameter = {}) {
  return axios({
    url: '/orderImage/orderImageInfoList',
    method: 'post',
    data: parameter
  })
}
  export function reviewImage (parameter = {}) {
    return axios({
      url: '/orderImage/reviewImage',
      method: 'post',
      data: parameter
    })
}
export function getOrderImage (parameter = {}) {
  return axios({
    url: '/orderImage/selectOrderImage',
    method: 'post',
    data: parameter
  })
}
export function showImageInfo (parameter = {}) {
  return axios({
    url: '/orderImage/showImageInfo',
    method: 'post',
    data: parameter
  })
}

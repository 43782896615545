<template>
  <div>
    <div style="padding: 15px;">
      <a-input :allowClear="true" style="width: 240px; margin-right: 15px;" placeholder="订单号" v-model="form.orderNo"/>
      <a-input :allowClear="true" style="width: 240px; margin-right: 15px;" placeholder="手机号" v-model="form.mobileNo"/>
      <a-button type="primary" @click="search()">{{ $t('search') }}</a-button>
    </div>
    <a-table
      :dataSource="list"
      :pagination="false"
      :loading="loading"
      :scroll = "{x: 1500}"
      rowKey="orderInfoId"
      bordered
      @change="handleTableChange">
      <a-table-column
        title="订单编号"
        dataIndex="orderNo"
        key="orderNo"
        align="center"
      />
      <a-table-column
        title="客户手机号"
        dataIndex="custMobileNo"
        key="custMobileNo"
        align="center"
      />
      <a-table-column
        title="应还金额"
        dataIndex="capital"
        key="capital"
        align="center"
      />
      <a-table-column
        title="已还金额"
        dataIndex="settledCapital"
        key="settledCapital"
        align="center"
      />
      <a-table-column
        title="应还利息"
        dataIndex="interest"
        key="interest"
        align="center"
      />
      <a-table-column
        title="已还利息"
        dataIndex="settledInterest"
        key="settledInterest"
        align="center"
      />
      <a-table-column
        title="滞纳金"
        dataIndex="lateFee"
        key="lateFee"
        align="center"
      />
      <a-table-column
      title="减免金额"
      dataIndex="deductionFee"
      key="deductionFee"
      align="center"
      />
      <a-table-column
        title="首位姓名"
        dataIndex="firstName"
        key="firstName"
        align="center"
      />
      <a-table-column
        title="中位姓名"
        dataIndex="secondName"
        key="secondName"
        align="center"
      />
      <a-table-column
        title="后位姓名"
        dataIndex="lastName"
        key="lastName"
        align="center"
      />
      <a-table-column
        title="审核状态"
        dataIndex="status"
        key="status"
        align="center"
      >
        <template slot-scope="text, record">
          <span>{{ text == 0 ? '未审核' : '已审核' }}</span>
        </template>
      </a-table-column>
      <a-table-column
        :title="$t('platformCollection.operation')"
        dataIndex="action"
        key="action"
        align="center"
      >
        <template slot-scope="text, record">
              <span>
                  <a href="javascript:;" @click="createGroup( record.orderInfoId)">审核</a>
              </span>
        </template>
      </a-table-column>
    </a-table>
    <create-group-modal ref="createGroupModal" @refresh="handleTableChange"/>
  </div>
</template>
<script>
  import CreateGroupModal from "@/views/webpublic/platformCollection/modules/CreateGroupModal";
  import {getOrderImageList,} from '@/webpublicapi/orderImageInfo'

  export default {
    components:{CreateGroupModal},
    data () {
      return {
        loading: false,
        form: {
          orderNo: undefined,
          mobileNo: undefined,

        },
        list: [],
      }
    },
    computed: {

    },
    created () {
      this.search();
      getOrderImageList().then(res => {
        this.userList = res.data || [];
      });
    },
    methods: {
      createGroup(id){
        this.$refs.createGroupModal.show(id);
      },
      handleTableChange () {
        this.search();
      },
      search () {
        this.loading = true;
        getOrderImageList(this.form).then(res => {
          this.loading = false;
          this.list = res && res.data && res.data.rows;
          this.total = res && res.data && res.data.total;
        }).catch(e => {
          this.loading = false;
          this.$message.error(e.message);
        });
      },
    }
  }
</script>

<template>
  <a-modal title="审核" :visible="modal.visible" :confirm-loading="modal.confirmLoading" @ok="submit()" @cancel="hide">
    <a-row>
      <a-col v-for="item in list" :span="12">
        <a-card hoverable style="width: 240px" :bordered="true">
          <img
            slot="cover"
            alt="example"
            :src="item.imgUrl"
          />
        </a-card>
      </a-col>
      <a-col :span="24" >
        <div style="display: flex;align-items:center;justify-content:center;margin-top: 12px;">
          <a-input v-model="model.amount" style="width: 100%; margin-right: 15px;" placeholder="审核金额"/>
        </div>
      </a-col>
    </a-row>
  </a-modal>
</template>
<script>
  import {getOrderImageInfoList,reviewImage} from '@/webpublicapi/orderImageInfo'
  export default {
    name: 'CreateGroupModal',
    data() {
      return {
        modal: {
          visible: false,
          confirmLoading: false,
        },
        form: this.$form.createForm(this),
        model: {
          orderInfoId: undefined,
          amount: undefined
        },
        list:[]
      }
    },
    methods: {
      show(orderInfoId) {
        this.list = []
        this.modal.visible = true
        this.model.orderInfoId = orderInfoId
        getOrderImageInfoList(this.model).then(res => {
          this.loading = false;
          this.list = res && res.data && res.data.rows;
          this.total = res && res.data && res.data.total;
        }).catch(e => {
          this.loading = false;
          this.$message.error(e.message);
        });
      },
      hide() {
        this.modal.visible = false
        this.model = {orderInfoId: undefined}
      },
      submit() {
        reviewImage({orderInfoId:this.model.orderInfoId,amount:Number(this.model.amount)}).then(res => {
          this.loading = false;
          this.total = res && res.data && res.data.total;
          this.hide()
          this.$emit("refresh")
        }).catch(e => {
          this.loading = false;
          this.$message.error(e.message);
        });
      }
    }
  }
</script>
<template>
  <a-modal title="查看" :visible="modal.visible" :confirm-loading="modal.confirmLoading" @ok="submit()" @cancel="hide">
    <a-row>
      <a-col v-show="list.length===0">
        没有图片
      </a-col>
      <a-col v-show="list.length>0" v-for="item in list" :span="12">
        <a-card hoverable style="width: 240px" :bordered="true">
          <img
            slot="cover"
            alt="example"
            :src="item.imgUrl"
          />
        </a-card>
      </a-col>
    </a-row>
  </a-modal>
</template>
<script>
  import {showImageInfo} from '@/webpublicapi/orderImageInfo'
  export default {
    name: 'ShowImageInfo',
    data() {
      return {
        modal: {
          visible: false,
          confirmLoading: false,
        },
        form: this.$form.createForm(this),
        model: {
          orderInfoId: undefined,
          type: undefined,
        },
        list:[]
      }
    },
    methods: {
      show(orderInfoId,type) {
        this.list = []
        this.modal.visible = true
        this.model.orderInfoId = orderInfoId
        this.model.type = type
        showImageInfo(this.model).then(res => {
            this.loading = false;
            this.list = res && res.data && res.data.rows;
            this.total = res && res.data && res.data.total;
            console.log("das"+this.list.length)
        }).catch(e => {
          this.loading = false;
          this.$message.error(e.message);
        });
      },
      hide() {
        this.modal.visible = false
        this.model = {orderInfoId: undefined}
      },
      submit() {
        this.hide()
      },
    }
  }
</script>